<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="pa-6 pt-10">
          <v-data-table
            :headers="headers"
            :items="candidates"
            :search="search"
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title>Candidatos</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-spacer />
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Procurar"
                  single-line
                  hide-details
                />
                <v-spacer />
              </v-toolbar>
            </template>

            <template #item.actions="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    @click="editItem(item)"
                    v-on="on"
                  >
                    mdi mdi-eye
                  </v-icon>
                </template>
                <span>Ver Dados</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ attrs, on }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    @click.prevent="viewCurriculum(item)"
                    v-on="on"
                  >
                    mdi-file-document-outline
                  </v-icon>
                </template>
                <span>Download Currículo</span>
              </v-tooltip>
            </template>

            <template #item.contactId="{ item }">
              <span>{{ item.contact.email }} | {{ item.contact.phone | phoneFormat }}</span>
            </template>

            <template #item.createdAt="{ item }">
              <span>{{ new Date(item.createdAt).toLocaleDateString() }}</span>
            </template>
          </v-data-table>

          <v-dialog
            v-model="dialog"
            hide-overlay
            persistent
            max-width="600px"
          >
            <form-candidates
              v-if="dialog"
              :populate-with="candidateToEdit"
              @close-dialog="closeDialog"
            />
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CandidatesApi from '../../../services/candidates.service';

export default {
  name: 'CandidatesList',
  components: {
    'form-candidates': () => import('./Form'),
  },

  filters: {
    phoneFormat(phoneNumber) {
      if (!phoneNumber) return '';
      const numbers = phoneNumber.replace(/\D/g, '');
      return numbers.length === 10
        ? numbers.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2 - $3')
        : numbers.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3 - $4');
    },
  },

  props: {
    jobIdProp: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Nome', value: 'name', sortable: true },
        { text: 'ID do Cargo', value: 'jobId', sortable: false },
        { text: 'Numcad', value: 'numcad', sortable: false },
        { text: 'Contato', value: 'contactId', sortable: false },
        { text: 'Data de Criação', value: 'createdAt', sortable: true },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      candidates: [],
      search: '',
      dialog: false,
      candidateToEdit: null,
    };
  },
  async created() {
    if (this.jobIdProp) {
      await this.loadCandidatesByJobId();
    } else {
      await this.loadCandidates();
    }
  },
  methods: {

    async loadCandidates() {
      try {
        this.candidates = await CandidatesApi.findAllCandidates();
      } catch (error) {
        console.error('Erro ao carregar candidatos:', error);
      }
    },

    async loadCandidatesByJobId() {
      try {
        this.candidates = await CandidatesApi.findCandidatesByJobId(this.jobIdProp);
      } catch (error) {
        console.error('Erro ao carregar candidatos:', error);
      }
    },

    viewCurriculum(item) {
      if (!item || !item.curriculo) {
        this.$toast.error('Currículo não disponível para este candidato.');
        return;
      }

      const url = item.curriculo;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        this.$toast.error('O URL do currículo é inválido.');
        return;
      }

      const win = window.open(url, '_blank');
      if (!win) {
        this.$toast.error('Um bloqueador de pop-up impediu a abertura do currículo. Por favor, desative o bloqueador e tente novamente.');
      }
    },

    closeDialog() {
      this.dialog = false;
      this.candidateToEdit = null;
    },
    editItem(item) {
      this.candidateToEdit = item;
      this.dialog = true;
    },
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
/* Estilos específicos do componente, se necessário */
</style>
